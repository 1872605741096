import React from "react";
import {
  Card,
  CardBody,
  CardExpandToggler,
} from "./../../components/card/card.jsx";

const Manifesto = () => {
  return (
    <div className="">
      <h1>Manifesto</h1>
      <div className="mt-4">
        <Card>
          <CardBody>
            <div className="d-flex fw-bold small mb-3 justify-content-end">
              <CardExpandToggler />
            </div>
            <h4>
            $COMMUNISM: Initial Comrade Offering
            </h4>
            <br></br>
            <h5>Comrades, unite with your chains!</h5>
            <p>
            In the spirit of levity and collectivism, we joyously unveil $COMMUNISM - not just a cryptocurrency, but a beacon of hope in the murky waters of capitalist crypto-seas. Built on the lightning-fast Sei blockchain, $COMMUNISM is set to overthrow the bourgeois financial order, one block at a time.
            </p>
            <br/>
            <h5>Our Manifesto: Equal Coins for All!</h5>
            <p>
            We dream of a world where digital wallets are not just the playground of the elite, but a common ground for every proletarian. $COMMUNISM is our rallying cry, our digital hammer and sickle, carving out a future of fairness in the blockchain. A ration for every man, a gun for every child, and a world where the great nation helps all.
            </p>
            <br />
            <h5>The Most Equal Tokenomics Ever Conceived:</h5>
            <p>Total Supply of the People: Exactly 1 Billion $COMMUNISM tokens - a nod to the year when the party really started.</p>

            <p>
              Revolutionary Features (Or How to Be a Good $COMMUNIST)
Remember the words of the great revolutionary, "You have nothing to lose but your chains... and perhaps a bit of fiat currency." With $COMMUNISM, we're not just disrupting the digital world; we're scripting a new chapter in the annals of economic history. Join the digital vanguard!
            </p>
            
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Manifesto;
