import React, { useEffect, useRef, useState } from "react";
import Countdown from "react-countdown";
import jsVectorMap from "jsvectormap";
import "jsvectormap/dist/maps/world.js";
import "jsvectormap/dist/css/jsvectormap.min.css";
import { useWeb3React } from "@web3-react/core";
import { useSelector, useDispatch } from "react-redux";
import { Oval } from "react-loader-spinner";
import { claimETH, claimGambleETH } from "../../api/CommunismWeb3.js";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import {
  Card,
  CardBody,
  CardExpandToggler,
} from "./../../components/card/card.jsx";

import LoloVideo from "../../assets/lolol2.mp4";

function Home() {
  const {
    nextAvailableDate,
    totalETHClaimed,
    balance,
    personalClaimedETH,
    ethToBeClaimed,
    burnedBalance,
  } = useSelector((state) => state.tokens);
  const dispatch = useDispatch();
  const { active, account, library } = useWeb3React();

  const [totalTransactions, setTotalTransactions] = useState(0);
  const [totalVolume, setTotalVolume] = useState(0);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [isETHClaimLoading, setIsETHClaimLoading] = useState(false);
  const [isETHGambleClaimLoading, setIsETHGambleClaimLoading] = useState(false);
  const [totalHolders, setTotalHolders] = useState(0);
  const [biggestBuys, setBiggestBuys] = useState([]);
  const [biggestClaims, setBiggestClaims] = useState([]);

  const countdownRef = useRef(null);
  function renderMap() {
    var theme = getComputedStyle(document.body)
      .getPropertyValue("--bs-theme")
      .trim();
    var inverse = getComputedStyle(document.body)
      .getPropertyValue("--bs-inverse")
      .trim();
    var inverseRgb = getComputedStyle(document.body)
      .getPropertyValue("--bs-inverse-rgb")
      .trim();
    var bodyFontFamily = getComputedStyle(document.body)
      .getPropertyValue("--bs-body-font-family")
      .trim();
    const map = document.getElementById("jvectorMap");
    const mapElm = document.querySelectorAll(".jvm-tooltip");

    if (map) {
      for (let i = 0; i < mapElm.length; i++) {
        mapElm[i].remove();
      }
      map.innerHTML = "";

      var markers = [
        { name: "Egypt", coords: [26.8206, 30.8025] },
        { name: "Russia", coords: [61.524, 105.3188] },
        // { name: "Canada", coords: [56.1304, -106.3468] },
        { name: "United States", coords: [37.0902, -95.7129] },
        { name: "Greenland", coords: [71.7069, -42.6043] },
        { name: "Brazil", coords: [-14.235, -51.9253] },
      ];
      new jsVectorMap({
        selector: "#jvectorMap",
        map: "world",
        zoomButtons: true,
        normalizeFunction: "polynomial",
        hoverOpacity: 0.5,
        hoverColor: false,
        zoomOnScroll: false,
        selectedRegions: ["LA", "RU", "CN", "KP"],
        // regionsSelectable: true,
        series: {
          regions: [
            {
              normalizeFunction: "polynomial",
            },
          ],
        },
        labels: {
          markers: {
            render: (marker) => marker.name,
          },
        },
        focusOn: {
          x: 0.5,
          y: 0.5,
          scale: 1,
        },
        markers: markers,
        markerStyle: {
          initial: {
            fill: theme,
            stroke: "none",
            r: 5,
          },
          hover: {
            fill: theme,
          },
        },
        markerLabelStyle: {
          initial: {
            fontFamily: bodyFontFamily,
            fontSize: "12px",
            fill: "rgba(" + inverseRgb + ", .75)",
          },
        },
        regionStyle: {
          initial: {
            fill: inverse,
            fillOpacity: 0.15,
            stroke: "none",
            strokeWidth: 0.4,
            strokeOpacity: 1,
          },
          hover: {
            fillOpacity: 0.5,
          },
          selected: {
            fill: "red",
            fillOpacity: 0.5,
          },
          selectedHover: {
            fillOpacity: 1,
          },
        },
        backgroundColor: "transparent",
      });
    }
  }

  const handleVideoLoaded = () => {
    setVideoLoaded(false);
  };

  const getTotalTransactions = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-API-KEY", "BQYWk4roYCgJJdkq6Ului01dWlHJ5P9z");

    var raw = JSON.stringify({
      query:
        'query MyQuery {\n  ethereum(network: ethereum){\n    smartContractEvents(smartContractAddress: {is: "0xD3FD49a874124ba9cE7aBF73d1cb3fFe92aCCb72"}\n    smartContractEvent: {is: "Transfer"}\n    )\n    {\n      count\n    }\n  }\n}\n',
      variables: "{}",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch("https://graphql.bitquery.io", requestOptions)
      .then(async (res) => {
        const { data } = await res.json();
        console.log(data, "data");
        setTotalTransactions(data.ethereum?.smartContractEvents[0].count);
      })
      .catch((error) => console.log("error", error));
  };

  const getTotalHolders = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-API-KEY", "BQYWk4roYCgJJdkq6Ului01dWlHJ5P9z");

    var raw = JSON.stringify({
      query:
        'query MyQuery {\n  EVM(network: eth, dataset: combined) {\n    BalanceUpdates(\n      where: {Currency: {SmartContract: {is: "0xD3FD49a874124ba9cE7aBF73d1cb3fFe92aCCb72"}}}\n    ){\n      No_Holders: count(distinct: BalanceUpdate_Address)\n    }\n  }\n}\n',
      variables: "{}",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://streaming.bitquery.io/graphql", requestOptions).then(
      async (res) => {
        const { data } = await res.json();
        // console.log(data, "result total holder");
        setTotalHolders(data.EVM.BalanceUpdates[0].No_Holders);
      }
    );
  };

  const getTotalVolume = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-API-KEY", "BQYWk4roYCgJJdkq6Ului01dWlHJ5P9z");

    var raw = JSON.stringify({
      query:
        'query MyQuery {\n  ethereum(network: ethereum){\n    dexTrades(\n      baseCurrency: {is: "0xD3FD49a874124ba9cE7aBF73d1cb3fFe92aCCb72"}\n    )\n    {\n      quoteAmount(in: ETH)\n    }\n  }\n}\n',
      variables: "{}",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://graphql.bitquery.io", requestOptions)
      .then(async (res) => {
        const { data } = await res.json();
        console.log(data, "data");
        setTotalVolume(data.ethereum?.dexTrades[0].quoteAmount);
      })
      .catch((error) => console.log("error", error));
  };

  const getBiggestBuys = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-API-KEY", "BQYWk4roYCgJJdkq6Ului01dWlHJ5P9z");

    var raw = JSON.stringify({
      query:
        'query MyQuery {\n  ethereum(network: ethereum){\n    dexTrades(\n      baseCurrency: {is: "0xD3FD49a874124ba9cE7aBF73d1cb3fFe92aCCb72"}\n      buyCurrency: {is: "0xD3FD49a874124ba9cE7aBF73d1cb3fFe92aCCb72"}\n      options: {desc: ["tradeAmount"], limit: 5}\n    )\n    {\n      transaction{hash}\n      tradeAmount(in: USD)\n      buyAmount\n    }\n  }\n}\n',
      variables: "{}",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://graphql.bitquery.io", requestOptions)
      .then(async (res) => {
        const { data } = await res.json();
        // console.log(data, " biggest buy data");
        setBiggestBuys(data.ethereum.dexTrades);
      })
      .catch((error) => console.log("error", error));
  };

  const getBiggestClaims = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-API-KEY", "BQYWk4roYCgJJdkq6Ului01dWlHJ5P9z");

    var raw = JSON.stringify({
      query:
        'query MyQuery {\n  ethereum(network: ethereum){\n    arguments(\n      options: {desc: ["value.value"], limit: 5}\n      smartContractAddress: {in: "0xD3FD49a874124ba9cE7aBF73d1cb3fFe92aCCb72"}\n      smartContractEvent: {is: "ClaimETHSuccessfully"}\n      argument: {is: "ethReceived"}\n    )\n    {\n      block {\n        height\n      }\n      argument{\n        name\n      }\n      value{\n        value\n      }\n      transaction{\n        hash\n      }\n    }\n  }\n}\n',
      variables: "{}",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://graphql.bitquery.io", requestOptions)
      .then(async (res) => {
        const { data } = await res.json();
        console.log(data, " biggest claim data");
        setBiggestClaims(data.ethereum.arguments);
        // setBiggestBuys(data.ethereum.dexTrades);
      })
      .catch((error) => console.log("error", error));
  };

  const onClickClaimETH = async () => {
    if (!active) {
      toast.error("Please connet Wallet!", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
      });
      return;
    }
    setIsETHClaimLoading(true);
    await claimETH(active, account, library, (status) => {
      setIsETHClaimLoading(false);
    });
  };

  const onClickClaimGambleETH = async () => {
    if (!active) {
      toast.error("Please connet Wallet!", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
      });
      return;
    }
    setIsETHGambleClaimLoading(true);
    await claimGambleETH(active, account, library, (status) => {
      setIsETHGambleClaimLoading(false);
    });
  };

  const openRadium = () => {
    window.open(
      ""
    );
  };

  const openTg = () => {
    window.open(
      "https://t.me/communismportal"
    );
  };

  useEffect(() => {
    renderMap();
    document.addEventListener("theme-reload", () => {
      renderMap();
    });
    getTotalTransactions();
    getTotalHolders();
    getTotalVolume();
    getBiggestClaims();
    getBiggestBuys();
  }, []);

  useEffect(() => {
    if (countdownRef.current) {
      countdownRef.current.start();
    }
  }, [nextAvailableDate]);

  return (
    <div className="home">
      <ul className="breadcrumb">
        <li className="breadcrumb-item">
          <a href="#/">HOME</a>
        </li>
        <li className="breadcrumb-item active">DASHBOARD</li>
      </ul>

      <h1 className="page-header header">Communism</h1>
      <ToastContainer
        limit={3}
        autoClose={5000}
        hideProgressBar={true}
        theme="colored"
      />
      <Countdown
        date={
          nextAvailableDate ? parseInt(nextAvailableDate) * 1000 : Date.now()
        }
        ref={countdownRef}
        renderer={(props) => (
          <div>
            {/* <Card>
              <CardBody>
                <div className="d-flex fw-bold small mb-3 justify-content-end">
                  <CardExpandToggler />
                </div>
                <div className="row mb-2 d-flex flex-wrap top_info">
                  <div className="col-xl-4 col-lg-6 total_info">
                    <p>Holders</p>
                    <label>{totalHolders}</label>
                  </div>
                  <div className="col-xl-4 col-lg-6 total_info">
                    <p>Transactions</p>
                    <label>{totalTransactions}</label>
                  </div>
                  <div className="col-xl-4 col-lg-6 total_info">
                    <p>Total Volume</p>
                    <label>{totalVolume.toFixed(4)} ETH</label>
                  </div>
                </div>
              </CardBody>
            </Card> */}
                        <div className="claim_buttons mt-4">
              <Card>
                <CardBody>
                  <div className="d-flex fw-bold small mb-3 justify-content-end">
                    <span className="flex-grow-1">Buy $COMMUNSIM</span>
                    <CardExpandToggler />
                  </div>
                  <div className="main">
                    <p>
                      
                    Comrades, the time has come to embrace our collective destiny and become part of the glorious and dignified communist nation. Choose this path if you are prepared to commit to the ideals of equality and solidarity that define our great movement. Take a bold step towards the future by purchasing $COMMUNISM on the truly red-blooded DEX, Astroport, where the revolution thrives.
                    </p>
                    <button onClick={openRadium}>
                      Buy on Astroport
                    </button>
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <div className="d-flex fw-bold small mb-3 justify-content-end">
                    <span className="flex-grow-1">Join The Fight</span>
                    <CardExpandToggler />
                  </div>
                  <div className="main">
                    <p>
                    Comrades, are you tired from the relentless toil of your labor, seeking a greater purpose? Embrace the revolutionary spirit! Ponder this: will you emerge as a hero for the working class? Connect with like-minded individuals and join your brothers and sisters on Telegram, where we unite in our quest for justice and equality. Let's collaborate and turn our collective dreams into reality. Join us now!
                    </p>
                    <button onClick={openTg}> Join Telegram
                    </button>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="video_info mt-4">
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-center">
                    <video controls>
                      <source src={LoloVideo} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </CardBody>
              </Card>
            </div>



            {/* <div className="buy_sell_info mt-4">
              <Card>
                <CardBody>
                  <div className="d-flex fw-bold small mb-3 justify-content-end">
                    <CardExpandToggler />
                  </div>
                  <p>13% Buy and Sell Tax</p>
                  <p>
                    8% is pragmatically and proportionally redistributed to the
                    holders in the form of ETH that you can claim whenever your
                    claim timer reaches 0. This will rise to become the full
                    allocation of the taxes and will be voted on by the people
                    to raise or lower before the contract is renounced.
                  </p>
                  <p>
                    5% Goes to Taking Over The World, and will be lowered to 1%
                    before we renounce the contract.
                  </p>
                </CardBody>
              </Card>
            </div> */}
            <div id="jsVectorMap" className="map_spread mt-4">
              <h2>Map-Spread Communism</h2>
              <Card>
                <CardBody className="group_body">
                  <div className="d-flex fw-bold small mb-3 justify-content-end map_wrap">
                    <div id="jvectorMap" style={{ height: "300px" }}></div>
                  </div>
                  <div className="text_box">
                    <p>
                      In the early days, communism found its origins in Russia,
                      paving the way for subsequent unsuccessful trials in
                      China. It is from these very foundations that we embarked
                      upon the dissemination of our unwavering communist
                      ideology. Grand conquests will be accompanied by burns and
                      or strategic acquisitions of $COMMUNSIM by our team,
                      serving as triumphant manifestations of our victory over
                      enemy regimes.
                    </p>
                  </div>
                </CardBody>
              </Card>
            </div>
            {/* <div className="chart_info mt-4">
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-center">
                    <div id="dexscreener-embed">
                      <iframe
                        src="https://dexscreener.com/ethereum/0x4Ebdb272A1B0D042210ac5AE39bb556e596228C6?embed=1&theme=dark&trades=0&info=0"
                        title="chart"
                      ></iframe>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div> */}
          </div>
        )}
      />
    </div>
  );
}

export default Home;
